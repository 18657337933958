import React from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react'
import OrderLocksTable from '../order-locks/OrderLocksTable';
import OrderAssortmentListStates from './OrderAssortmentListStates';

export function feature_js_order_assortment_locks(el) {
    var $el = $(el);

    const translations = {
        header: $el.attr('data-tr-header'), //MAGAP_Header
        unlock: $el.attr('data-tr-unlock'), //TBC_Unlock
        lock_and_save: $el.attr('data-tr-lock-save'), //TBC_SaveAndLock
        new: $el.attr('data-tr-new'), //TBC_New
        delete: $el.attr('data-tr-delete'), //TBC_Delete
        save: $el.attr('data-tr-save'), //TBC_Save
        instructions: $el.attr('data-tr-instructions'), //OL_Instructions
        no_lock_type_chosen: $el.attr('data-tr-no-lock-time-chosen'), //OL_NoLockTypeCHosen 
        multiple_lock_times_used: $el.attr('data-tr-multiple-lock-times-used'), //OL_MultipleLockTimesUsed
        sunday: $el.attr('data-tr-sunday'), //OL_Sunday
        monday: $el.attr('data-tr-monday'), //OL_Monday
        tuesday: $el.attr('data-tr-tuesday'), //OL_Tuesday
        wednesday: $el.attr('data-tr-wednesday'), //OL_Wednesday
        thursday: $el.attr('data-tr-thursday'), //OL_Thursday
        friday: $el.attr('data-tr-friday'), //OL_Friday
        saturday: $el.attr('data-tr-saturday'), //OL_Saturday
        same_day: $el.attr('data-tr-same-day'), //OL_SameDay
        till: $el.attr('data-tr-till'), //OL_Till
        name: $el.attr('data-tr-name'), //OL_Name
        lock_time: $el.attr('data-tr-lock-time'), //OL_LockTime
        weekday: $el.attr('data-tr-weekday'), //OL_WeekDay
        days: $el.attr('data-tr-days'), //OL_Days
        time: $el.attr('data-tr-time'), //OL_Time
        last_order_type: $el.attr('data-tr-last-order-type'), //OL_LastOrderType
        no_content: $el.attr('data-tr-no-content'), //OL_NoContent
        before: $el.attr('data-tr-before'), //OL_Before
        deactivateDayConfirmation: $el.attr('data-tr-deactivate-day-confirmation')
    };
    const locked = $el.attr('data-lock-status') == 'locked';
    const settings = $el.attr('data-lock-settings');

    ReactDOM.render(<OrderAssortmentLocks translations={translations} locked={locked} settings={settings} store={OrderAssortmentListStates} />, el[0]);
}


@observer
class OrderAssortmentLocks extends React.Component {
    constructor(props) {
        super(props);

        this.weekDays = [this.props.translations.sunday, this.props.translations.monday, this.props.translations.tuesday, this.props.translations.wednesday, this.props.translations.thursday, this.props.translations.friday, this.props.translations.saturday];

        this.state = {
            data: this.toOrderLockTableData(JSON.parse(props.settings))
        };

        this.onChange();
        this.onActiveChange = this.onActiveChange.bind(this);
    }

    onChange = () => {
        this.props.store.setLockSettings(this.state.data);

        const input = $("[id$=hdfOrderAssortmentLockSettings]")[0];
        input.value = JSON.stringify(this.toLockTimesModel(this.state.data));
    }

    onActiveChange(day, active) {
        var hasAssignedClients = this.props.store.daysWithClients.includes(day.dayOfTheWeek);
        if (!active && hasAssignedClients) {
            var message = this.props.translations.deactivateDayConfirmation.replace('{dayOfWeek}', this.weekDays[day.dayOfTheWeek]);
            return confirm(message);
        }
    }

    toOrderLockTableData = (settings) => {
        return [
            { dayOfTheWeek: 1, active: settings.monday.active, days: settings.monday.days, time: (settings.monday.timeOfDay) ? settings.monday.timeOfDay : "23:59" },
            { dayOfTheWeek: 2, active: settings.tuesday.active, days: settings.tuesday.days, time: (settings.tuesday.timeOfDay) ? settings.tuesday.timeOfDay : "23:59" },
            { dayOfTheWeek: 3, active: settings.wednesday.active, days: settings.wednesday.days, time: (settings.wednesday.timeOfDay) ? settings.wednesday.timeOfDay : "23:59" },
            { dayOfTheWeek: 4, active: settings.thursday.active, days: settings.thursday.days, time: (settings.thursday.timeOfDay) ? settings.thursday.timeOfDay : "23:59" },
            { dayOfTheWeek: 5, active: settings.friday.active, days: settings.friday.days, time: (settings.friday.timeOfDay) ? settings.friday.timeOfDay : "23:59" },
            { dayOfTheWeek: 6, active: settings.saturday.active, days: settings.saturday.days, time: (settings.saturday.timeOfDay) ? settings.saturday.timeOfDay : "23:59" },
            { dayOfTheWeek: 0, active: settings.sunday.active, days: settings.sunday.days, time: (settings.sunday.timeOfDay) ? settings.sunday.timeOfDay : "23:59" }
        ];
    }

    toLockTimesModel(data) {
        return {
            monday: {
                active: data[0].active,
                days: data[0].days,
                timeOfDay: data[0].time
            },
            tuesday: {
                active: data[1].active,
                days: data[1].days,
                timeOfDay: data[1].time
            },
            wednesday: {
                active: data[2].active,
                days: data[2].days,
                timeOfDay: data[2].time
            },
            thursday: {
                active: data[3].active,
                days: data[3].days,
                timeOfDay: data[3].time
            },
            friday: {
                active: data[4].active,
                days: data[4].days,
                timeOfDay: data[4].time
            },
            saturday: {
                active: data[5].active,
                days: data[5].days,
                timeOfDay: data[5].time
            },
            sunday: {
                active: data[6].active,
                days: data[6].days,
                timeOfDay: data[6].time
            }
        }
    }

    render() {
        return (
            <div className='orderLocks'>
                <div className='section'>
                    <OrderLocksTable locked={this.props.locked} data={this.state.data} onDataChange={this.onChange} translations={this.props.translations} activeCheck="true" onActiveChange={this.onActiveChange}>
                    </OrderLocksTable>
                </div>
            </div>
        );
    }
}

export default OrderAssortmentLocks;