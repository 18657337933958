import React from 'react';
import ReactDOM from 'react-dom';
import OrderLocksAccordion from './OrderLocksAccordion';
import { get, post } from './OrderLockApiClient';
import ClipLoader from "react-spinners/ClipLoader";



export function feature_js_order_locks(el) {
    var $el = $(el);

    const translations = {
        header: $el.attr('data-tr-header'), //MAGAP_Header
        unlock: $el.attr('data-tr-unlock'), //TBC_Unlock
        lock_and_save: $el.attr('data-tr-lock-save'), //TBC_SaveAndLock
        new: $el.attr('data-tr-new'), //TBC_New
        delete: $el.attr('data-tr-delete'), //TBC_Delete
        save: $el.attr('data-tr-save'), //TBC_Save
        instructions: $el.attr('data-tr-instructions'), //OL_Instructions
        no_lock_type_chosen: $el.attr('data-tr-no-lock-time-chosen'), //OL_NoLockTypeCHosen 
        multiple_lock_times_used: $el.attr('data-tr-multiple-lock-times-used'), //OL_MultipleLockTimesUsed
        sunday: $el.attr('data-tr-sunday'), //OL_Sunday
        monday: $el.attr('data-tr-monday'), //OL_Monday
        tuesday: $el.attr('data-tr-tuesday'), //OL_Tuesday
        wednesday: $el.attr('data-tr-wednesday'), //OL_Wednesday
        thursday: $el.attr('data-tr-thursday'), //OL_Thursday
        friday: $el.attr('data-tr-friday'), //OL_Friday
        saturday: $el.attr('data-tr-saturday'), //OL_Saturday
        same_day: $el.attr('data-tr-same-day'), //OL_SameDay
        till: $el.attr('data-tr-till'), //OL_Till
        name: $el.attr('data-tr-name'), //OL_Name
        lock_time: $el.attr('data-tr-lock-time'), //OL_LockTime
        weekday: $el.attr('data-tr-weekday'), //OL_WeekDay
        days: $el.attr('data-tr-days'), //OL_Days
        time: $el.attr('data-tr-time'), //OL_Time
        last_order_type: $el.attr('data-tr-last-order-type'), //OL_LastOrderType
        no_content: $el.attr('data-tr-no-content'), //OL_NoContent
        before: $el.attr('data-tr-before'), //OL_Before
    };

    var element = document.getElementById('order-locks');
    ReactDOM.render(<OrderLocks translations={translations} />, element);
}



class OrderLocks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            showLoader: true,
            orderLockMenuGroup: [],
            locked: true
        };
    }

    componentDidMount() {
        this.getValues();
    }

    getValues = () => {
        this.setState({ showLoader: true });
        get(
            (data) => {
                if (data.success) {
                    const updatedData = this.mapData(data.data);
                    this.setState({
                        orderLockMenuGroup: updatedData
                    });
                    this.forceUpdate();
                } else {
                    alert(data.error); // no design
                }
                this.setState({
                    showLoader: false,
                    loaded: true
                });
            },
            () => {
                this.setState({
                    orderLockMenuGroup: [],
                    showLoader: false,
                    loaded: true
                });
            });
    };

    mapData = (data) => {
        const mappedData = data.map(function (element) {
            return {
                idMenuGroup: element.id,
                name: element.name,
                isSelected: false,
                data: [
                    { dayOfTheWeek: 1, days: element.lockTimes.monday.days, time: (element.lockTimes.monday.timeOfDay) ? element.lockTimes.monday.timeOfDay : "23:59" },
                    { dayOfTheWeek: 2, days: element.lockTimes.tuesday.days, time: (element.lockTimes.tuesday.timeOfDay) ? element.lockTimes.tuesday.timeOfDay : "23:59" },
                    { dayOfTheWeek: 3, days: element.lockTimes.wednesday.days, time: (element.lockTimes.wednesday.timeOfDay) ? element.lockTimes.wednesday.timeOfDay : "23:59" },
                    { dayOfTheWeek: 4, days: element.lockTimes.thursday.days, time: (element.lockTimes.thursday.timeOfDay) ? element.lockTimes.thursday.timeOfDay : "23:59" },
                    { dayOfTheWeek: 5, days: element.lockTimes.friday.days, time: (element.lockTimes.friday.timeOfDay) ? element.lockTimes.friday.timeOfDay : "23:59" },
                    { dayOfTheWeek: 6, days: element.lockTimes.saturday.days, time: (element.lockTimes.saturday.timeOfDay) ? element.lockTimes.saturday.timeOfDay : "23:59" },
                    { dayOfTheWeek: 0, days: element.lockTimes.sunday.days, time: (element.lockTimes.sunday.timeOfDay) ? element.lockTimes.sunday.timeOfDay : "23:59" },]
            }
        });
        return mappedData;
    }

    handleLockedClick = () => {
        if (!this.state.locked) {
            this.save();
        }
        this.setState({
            locked: !this.state.locked
        });
        this.forceUpdate();

    }

    addItem = () => {

        this.state.orderLockMenuGroup.unshift({
            idMenuGroup: "new-" + this.uuidv4(),
            name: "default name",
            isSelected: false,
            data: [
                { dayOfTheWeek: 1, days: 0, time: "23:59" },
                { dayOfTheWeek: 2, days: 0, time: "23:59" },
                { dayOfTheWeek: 3, days: 0, time: "23:59" },
                { dayOfTheWeek: 4, days: 0, time: "23:59" },
                { dayOfTheWeek: 5, days: 0, time: "23:59" },
                { dayOfTheWeek: 6, days: 0, time: "23:59" },
                { dayOfTheWeek: 0, days: 0, time: "23:59" },
            ]
        })
        this.setState({
            orderLockMenuGroup: this.state.orderLockMenuGroup
        });


    }

    removeItems = () => {
        this.state.orderLockMenuGroup = this.state.orderLockMenuGroup.filter(function (item) {
            return !item.isSelected;
        });
        this.setState({
            orderLockMenuGroup: this.state.orderLockMenuGroup
        });
    }

    save = () => {
        this.setState({
            showLoader: true,
        });

        const mappedData = this.state.orderLockMenuGroup.map(function (element) {
            return {
                id: element.idMenuGroup,
                name: element.name,
                lockTimes: {
                    monday: {
                        days: element.data[0].days,
                        timeOfDay: element.data[0].time
                    },
                    tuesday: {
                        days: element.data[1].days,
                        timeOfDay: element.data[1].time
                    },
                    wednesday: {
                        days: element.data[2].days,
                        timeOfDay: element.data[2].time
                    },
                    thursday: {
                        days: element.data[3].days,
                        timeOfDay: element.data[3].time
                    },
                    friday: {
                        days: element.data[4].days,
                        timeOfDay: element.data[4].time

                    },
                    saturday: {
                        days: element.data[5].days,
                        timeOfDay: element.data[5].time

                    },
                    sunday: {
                        days: element.data[6].days,
                        timeOfDay: element.data[6].time

                    }
                }
            }
        });
        
        post(
            (data) => {
                this.setState({
                    showLoader: false,
                    loaded: true
                });
                if (data.success) {
                    const updatedData = this.mapData(data.data);
                    this.setState({
                        orderLockMenuGroup: updatedData
                    });
                } else {
                    alert(data.error);
                }
            },
            mappedData,
            () => {
                this.setState({ showLoader: false });
            });
    }

    render() {
        const loaderStyle = { position: "absolute", top: "20%", left: "50%", transform: "translate(-20%, -50%)" };

        if (!this.state.loaded) {
            return (<div>
                        {this.state.showLoader &&
                    <div style={loaderStyle}>
                        <ClipLoader />
                    </div>
                }
            </div>);
        }
        return (
            <div className='orderLocks'>
                <div className='nav nav-toolbox'>
                    <input type="button" className={this.state.locked ? 'lockButton-Locked' : 'lockButton-UnLocked'} onClick={this.handleLockedClick} title={this.state.locked ? this.props.translations.unlock : this.props.translations.lock_and_save} /></div>
                <div className='container'>
                    <div className='titleBar'>
                        <div className='titleBar-text'>{this.props.translations.header}</div>
                        <div className='titleBar-image'><img className='greenTitleBar' /></div>
                    </div>
                    <div className='content' >
                        <div className='data'>
                            <input type="button" disabled={this.state.locked} className={`actionButton  ${this.state.locked ? 'newButton-Locked' : 'newButton-UnLocked'}`} onClick={this.addItem} title={this.props.translations.new} />
                            &nbsp;
                            <input type="button" disabled={this.state.locked} className={`actionButton  ${this.state.locked ? 'deleteButton-Locked' : 'deleteButton-UnLocked'}`} onClick={this.removeItems} title={this.props.translations.delete} />
                            &nbsp;
                            <input type="button" disabled={this.state.locked} className={`actionButton  ${this.state.locked ? 'saveButton-Locked' : 'saveButton-UnLocked'}`} onClick={this.save} title={this.props.translations.save} />
                            <div >
                                <OrderLocksAccordion allowMultipleOpen items={this.state.orderLockMenuGroup} locked={this.state.locked} translations={this.props.translations} />
                            </div>
                        </div>
                        <div>
                            <div className='instructions' dangerouslySetInnerHTML={{ __html: this.props.translations.instructions }}  />
                        </div>
                    </div>
                </div>
                {this.state.showLoader &&
                    <div style={loaderStyle}>
                        <ClipLoader />
                    </div>
                }
            </div>
        );
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
}

export default OrderLocks;