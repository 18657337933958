import Animation from './animation';

const Filter = {

    dropdown: {},
    rows: {},

    populateDropDown: types => $(types).each((i, type) => {
        Filter.dropdown.append($('<option>', {
            text: type.text,
            value: type.type
        }));
    }),

    getAllRowTypes: () => Filter.rows.map(
        i => ({
            text: $(Filter.rows[i]).find('input').data('text'),
            type: $(Filter.rows[i]).find('input').data('type')
        })
    ).toArray(),

    onDropDownSelectedIndexDiDChanged: event => {
        const text = Filter.dropdown.find(':selected').val();
        Filter.rows.each((i, row) => {
            row = $(row);
            if (text === row.data('type') || text === 'ALL') {
                if (!row.hasClass('recycle-items__row--visible')) {
                    Animation.showRows(row);
                }
            } else {
                Animation.hideRows(row);
            }
        });
    },

    initDropdownFilter: (dropdown, rows) => {
        Filter.rows = rows;
        Filter.dropdown = dropdown;
        const allTypes = Filter.getAllRowTypes();
        const reducedTypes = _.uniq(allTypes, x => x.type);
        Filter.populateDropDown(reducedTypes);
        Filter.dropdown.on('change', Filter.onDropDownSelectedIndexDiDChanged);
    }
}

export default Filter;
