"use strict";

import React from 'react';
import ReactDOM from 'react-dom';
import { get, post } from './IntegrationPlatformApiClient';
import ClipLoader from "react-spinners/ClipLoader";

export function feature_js_integrationplatform(el) {
    var $el = $(el);

    const translations = {
        configuration: $el.attr("data-tr-configuration"),
        manualRun: $el.attr("data-tr-manualrun"),
        triggers: $el.attr("data-tr-triggers"),
        logs: $el.attr("data-tr-logs"),
        from: $el.attr("data-tr-from"),
        to: $el.attr("data-tr-to"),
        name: $el.attr("data-tr-name"),
        state: $el.attr("data-tr-state"),
        nocontent: $el.attr("data-tr-nocontent"),
        lastExecutionDate: $el.attr("data-tr-lastexecutiondate"),
        nextExecutionDate: $el.attr("data-tr-nextexecutiondate"),
        date: $el.attr("data-tr-date"),
        status: $el.attr("data-tr-status"),
        message: $el.attr("data-tr-message"),
        manualRunInstructions: $el.attr("data-tr-manualruninstructions"),
        manualRunMessage: $el.attr("data-tr-manualrunmessage"),
        noContent: $el.attr("data-tr-nocontent"),
        noConfigurations: $el.attr("data-tr-noconfigurations"),
        refresh: $el.attr("data-tr-refresh"),
        fileArchive: $el.attr("data-tr-filearchive"),
        start: $el.attr("data-tr-start"),
        normal: $el.attr("data-tr-normal"),
        paused: $el.attr("data-tr-paused"),
        complete: $el.attr("data-tr-complete"),
        none: $el.attr("data-tr-none"),
        blocked: $el.attr("data-tr-blocked"),
        activatedeactivate: $el.attr("data-tr-activatedeactivate"),
        activate: $el.attr("data-tr-activate"),
        deactivate: $el.attr("data-tr-deactivate"),
        error: $el.attr("data-tr-error"),
        warning: $el.attr("data-tr-warning"),
        information: $el.attr("data-tr-information"),
        trace: $el.attr("data-tr-trace"),
        showforselectedperiod: $el.attr("data-tr-showforselectedperiod"),
        manualRunConfirmation: $el.attr("data-tr-manualrunconfirmation"),
        manualRunConfirmationNote: $el.attr("data-tr-manualrunnote")
    };

    var element = document.getElementById('integration-platform');
    ReactDOM.render(<IntegrationPlatform translations={translations}  />, element);
}

class IntegrationPlatform extends React.Component {
    constructor(props) {
        super(props);

        const runFromDate = new Date();
        runFromDate.setDate(0);
        runFromDate.setDate(1);

        const runToDate = new Date();
        runToDate.setDate(0);

        const today = new Date();

        this.state = {
            configurations: [],
            triggers: [],
            logs: [],
            showLoader: true,
            selectedConfigValue: "",
            runFromDate: runFromDate.toISOString().split('T')[0],
            initialRunFromDate: runFromDate.toISOString().split('T')[0],
            runToDate: runToDate.toISOString().split('T')[0],
            initialRunToDate: runToDate.toISOString().split('T')[0],
            logsFromDate: today.toISOString().split('T')[0],
            logsToDate: today.toISOString().split('T')[0],
            manualRunMessage: "",
            loaded: false

    };
        
    }

    componentDidMount() {
        this.getValues("configuration");
    }

    getValues = (type) => {
        this.setState({ showLoader: true });
        get(
            (data) => {
                this.setState({
                    configurations: data.configurations,
                    triggers: data.triggers,
                    logs: data.logs,
                    selectedConfigValue: data.selectedConfigurationId,
                    showLoader: false,
                    loaded: true
                });
            },
            () => {
                this.setState({
                    configurations: [],
                    triggers: [],
                    logs: [],
                    selectedConfigValue: "",
                    showLoader: false,
                    loaded:true
                });
            }
            , type);
    };

    translateTriggerState = (state) => {
        var translation;
        switch (state.toLowerCase()) {
            case "complete":
                translation = this.props.translations.complete;
                break;
            case "normal":
                translation = this.props.translations.normal;
                break;
            case "paused":
                translation = this.props.translations.paused;
                break;
            case "none":
                translation = this.props.translations.none;
                break;
            case "error":
                translation = this.props.translations.error;
                break;
            case "blocked":
                translation = this.props.translations.blocked;
                break;
            default:
                translation = state;
        }
        return translation;
    }

    getTriggerTableContent = () => {
        return this.state.triggers.map(r => {
            return (
                <tr key={r.id}>
                    <td>{r.name}</td>
                    <td>{this.translateTriggerState(r.status)}</td>
                    <td>{r.prevExecutionTime}</td>
                    <td>{r.nextExecutionTime}</td>
                    <td>
                        <button onClick={(e) => this.toggleTrigger(r)}
                            title={r.status.toLowerCase() === "normal" ? this.props.translations.deactivate : this.props.translations.activate}
                        ><span className={`fa ${r.status.toLowerCase() === "normal"
                            ? 'fa-pause'
                            : 'fa-play'}`}></span></button>
                    </td>
                </tr>
            );
        });
    }

    translateLogType = (type) => {
        var translation;
        switch (type.toLowerCase()) {
            case "error":
                translation = this.props.translations.error;
                break;
            case "warning":
                translation = this.props.translations.warning;
                break;
            case "information":
                translation = this.props.translations.information;
                break;
            case "trace":
                translation = this.props.translations.trace;
                break;
            default:
                translation = type;
        }
        return translation;
    }

    convertUTCDateToLocalDate = (date) => {
        var utcDate = new Date(date);
        var newDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000);
        var offset = utcDate.getTimezoneOffset() / 60;
        var hours = utcDate.getHours();
        newDate.setHours(hours - offset);
        return newDate.toLocaleString();
    }


    getLogTableContent = () => {
        return this.state.logs.map((r, index) => {
            return (
                <tr key={index}>
                    <td>{this.convertUTCDateToLocalDate(r.created)}</td>
                    <td>{this.translateLogType(r.type)}</td>
                    <td>{r.logMessage}</td>
                </tr>
            );
        });
    }


    getEmptyTableContent = (colspan) => {
        return (
            <tr>
                <td className="text-center" colSpan={colspan}>{this.props.translations.noContent}</td>
            </tr>
        );
    }

    refresh = (event) => {
        this.getValues("configuration/" + this.state.selectedConfigurationId);
    }

    handleConfigValueChange = (event) => {
        this.getValues("configuration/" + event.target.value);
    }

    handleRunFromDateValueChange = (event) => {
        this.setState({ runFromDate: event.target.value });
    }

    handleRunToDateValueChange = (event) => {
        this.setState({ runToDate: event.target.value });
    }

    handleLogsFromDateValueChange = (event) => {
        this.setState({ logsFromDate: event.target.value });
    }

    handleLogsToDateValueChange = (event) => {
        this.setState({ logsToDate: event.target.value });
    }

    toggleTrigger = (trigger) => {
        this.setState({ showLoader: true });
        post(
            (data) => {
                this.setState({
                    triggers: data,
                    showLoader: false
                });
            },
            trigger,
            () => {
                this.setState({ showLoader: false });
            }
            , "toggle-trigger");
    }

    getLogs = () => {
        //alert(Intl.DateTimeFormat().resolvedOptions().timeZone);
        this.setState({ showLoader: true });
        post(
            (data) => {
                this.setState({
                    logs: data,
                    showLoader: false
                });
            },
            { from: this.state.logsFromDate, to: this.state.logsToDate, selectedConfigurationId: this.state.selectedConfigValue } ,
            () => {
                this.setState({ showLoader: false });
            }
            , "logs");
    }

    manualRun = () => {
        if (this.state.manualRunMessage === "") {

            let confirmationMessage = this.props.translations.manualRunConfirmation;
            if (this.state.runFromDate != this.state.initialRunFromDate || this.state.runToDate != this.state.initialRunToDate) {
                confirmationMessage = confirmationMessage + "\n\n" + this.props.translations.manualRunConfirmationNote;
            }
            if (confirm(confirmationMessage)) {
                this.setState({ showLoader: true });
                const payload = {
                    from: this.state.runFromDate,
                    to: this.state.runToDate,
                    selectedConfigurationId: this.state.selectedConfigValue
                };
                post(
                    (data) => {

                        this.setState({
                            showLoader: false,
                            manualRunMessage: this.props.translations.manualRunMessage
                        });
                        setTimeout(() => this.setState({ manualRunMessage: "" }), 3000);
                    },
                    payload,
                    () => {
                        this.setState({ showLoader: false });
                    }
                    , "manual-run");
            }
        }
    }

    render() {
        if (!this.state.loaded) {
            return (<div/>);
        }

        if (this.state.configurations.length > 0) {
            const loaderStyle = { position: "absolute", top: "20%", left: "50%", transform: "translate(-20%, -50%)" };
            return (
                <div>
                    <table style={{ paddingBottom: "15px", width: "300px" }}>
                        <tbody>
                        <tr>
                            <td width="20">
                            </td>
                            <td width="130">
                                <span className="PageLabelClass">{this.props.translations.configuration}</span></td>
                            <td>
                                <select className="DropDownListClass" width="200" value={this.state.selectedConfigValue} onChange={this.handleConfigValueChange}>
                                    {this.state.configurations.map((option) => (
                                        <option key={option.id} value={option.id}>{option.name}</option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                    <button onClick={this.refresh} title={this.props.translations.refresh}><span className="fa fa-refresh"></span></button>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <Tabs defaultActiveTabIndex="0">
                        <Tab title={this.props.translations.manualRun}>
                            <div style={{ padding: "10px" }}>
                                {this.props.translations.manualRunInstructions}<br/><br/>
                                <b>{this.props.translations.from}:</b> <input type="date" value={this.state.runFromDate} onChange={this.handleRunFromDateValueChange} /> <b>{this.props.translations.to}:</b> <input type="date" value={this.state.runToDate} onChange={this.handleRunToDateValueChange} /> <button onClick={this.manualRun} title={this.props.translations.start}><span className="fa fa-play"></span></button>
                            </div>
                            <div style={{ padding: "10px" }}>
                                <b>{this.state.manualRunMessage}</b>
                            </div>
                        </Tab>
                        <Tab title={this.props.translations.triggers}>
                            <div style={{ padding: "10px" }}>
                                <table className="table table-bordered table-striped separate">
                                    <thead>
                                    <tr>
                                        <th>{this.props.translations.name}</th>
                                        <th>{this.props.translations.state}</th>
                                        <th>{this.props.translations.lastExecutionDate}</th>
                                        <th>{this.props.translations.nextExecutionDate}</th>
                                        <th>{this.props.translations.activatedeactivate}</th>
                                    </tr>

                                    </thead>
                                    <tbody>
                                    {this.state.triggers.length > 0
                                        ? this.getTriggerTableContent()
                                        : this.getEmptyTableContent(5)}
                                    </tbody>
                                </table>
                            </div>
                        </Tab>
                        <Tab title={this.props.translations.logs}>
                            <div style={{ padding: "10px" }}>
                                <div style={{ paddingBottom: "10px" }}>
                                    <b>{this.props.translations.from}:</b> <input type="date" value={this.state.logsFromDate} onChange={this.handleLogsFromDateValueChange} /> <b>{this.props.translations.to}:</b> <input type="date" value={this.state.logsToDate} onChange={this.handleLogsToDateValueChange} /> <button onClick={this.getLogs} title={this.props.translations.showforselectedperiod}><span className="fa fa-play"></span></button>
                                </div>
                                <table className="table table-bordered table-striped separate">
                                    <thead>
                                    <tr>
                                        <th width="300">{this.props.translations.date}</th>
                                        <th>{this.props.translations.status}</th>
                                        <th>{this.props.translations.message}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.logs.length > 0
                                        ? this.getLogTableContent()
                                        : this.getEmptyTableContent(3)}
                                    </tbody>
                                </table>

                            </div>
                        </Tab>
                    </Tabs>
                    {this.state.showLoader &&
                        <div style={loaderStyle}>
                            <ClipLoader/>
                        </div>
                    }
                </div>
            );
        } else {
            return (
                <div>
                    <div style={{ padding: "10px", textAlign: "center", backgroundColor: "white" }}>
                        <b>{ this.props.translations.noConfigurations }</b>
                    </div>
                </div>
                );
        }
        
    }
}

class Tabs extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            activeTabIndex: this.props.defaultActiveTabIndex
        };
        this.handleTabClick = this.handleTabClick.bind(this);
    }

    handleTabClick(tabIndex) {
        this.setState({
            activeTabIndex: tabIndex === this.state.activeTabIndex ? this.props.defaultActiveTabIndex : tabIndex
        });
    }

    renderChildrenWithTabsApiAsProps() {
        return React.Children.map(this.props.children, (child, index) => {
            return React.cloneElement(child, {
                onClick: this.handleTabClick,
                tabIndex: index,
                isActive: index == this.state.activeTabIndex
            });
        });
    }

    
    renderActiveTabContent() {
        const { children } = this.props;
        const { activeTabIndex } = this.state;
        if (children[activeTabIndex]) {
            return children[activeTabIndex].props.children;
        }
    }

    render() {
        return (
            <div className="ui-tabs ui-widget ui-widget-content ui-corner-all">
                <ul className="ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all" role="tablist">
                    {this.renderChildrenWithTabsApiAsProps()} 
                </ul>
                <div className="tabs-active-content">
                    {this.renderActiveTabContent()}
                </div>
            </div>
        );
    }
};

export const Tab = (props) => {
    return (
        <li className={`ui-state-default ui-corner-top ${props.isActive ? "ui-tabs-active ui-state-active" : ""}`} role="tab">
            <a className='ui-tabs-anchor'
               onClick={(event) => {
                   event.preventDefault();
                   props.onClick(props.tabIndex);
               }}>
                <span className="PageLabelClass">{props.title}</span>
            </a>
        </li>
    );
}
