"use strict";

import React from "react";
import { Flex, Box } from "../../../../react-shared/flexbox";
import { NotificationStore } from "../../Store/notification-store";
import { observer } from "mobx-react";
import moment from "moment";

@observer
export class EmptyState extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ textAlign: "center", padding: "60px 70px" }}>
        <div
          style={{ fontSize: "1.3em", fontWeight: "bold", color: "#676767", marginBottom:"10px" }}
        >
          {this.props.headerText}
        </div>
        <div
          style={{ fontSize: "1.2em", color: "#676767", marginBottom:"20px" }}
        >
          {this.props.message}
        </div>
        <div
          style={{ fontSize: "1.2em", color: "#676767", marginBottom:"20px" }}
        >
          {this.props.message2}
        </div>
      </div>
    );
  }
}
