const Sort = {

    table: {},

    getAllRows: () => Sort.table.find('tbody tr'),

    getRowsWithDates: () =>
        Sort.getAllRows().map(
            (i, row) => ({
                date: new Date($(row).data('date')),
                row: row
            })
        ).get(),

    arrangeTableRows: (rows, direction) =>
        rows.sort((x, y) => x.date - (direction * y.date)),

    sortColumn: direction => {
        const dates = Sort.getRowsWithDates();
        Sort.arrangeTableRows(dates, direction);
        $(dates).each((i, row) => Sort.table.get(0).tBodies[0].appendChild(row.row));
    },

    columnWillSort: event => {
        const column = $(event.currentTarget);
        column.toggleClass('recycle-item__header--asc recycle-item__header--desc');
        column.find('span').toggleClass('ASC DESC');
        column.hasClass('recycle-item__header--asc') ? Sort.sortColumn(1) : null;
        column.hasClass('recycle-item__header--desc') ? Sort.sortColumn(-1) : null;

    },

    initSortColumn: table => {
        Sort.table = table;
        const columnHeader = table.find('.recycle-item__sortable');
        const tableRows = table.find('tbody tr');
        columnHeader.on('click', Sort.columnWillSort);
        Sort.sortColumn(1);
    }
}

export default Sort;
