"use strict";

import React from "react";
import ReactDOM from "react-dom";
import MealEconomyStore from "./Store/meal-economy-store";
import { observer } from "mobx-react";
import { GridCell, Grid } from "../../react-shared/Grid/Grid";

export function feature_js_meal_economy(el) {

  let isLocked = $(el[0]).attr("isLocked").toLowerCase() == 'true';
  let customerCategoryId = $(el[0]).attr("customerCategoryId");
  MealEconomyStore.customerCategoryId = customerCategoryId;
  MealEconomyStore.isLocked = isLocked;
  MealEconomyStore.loadList();
  MealEconomyStore.loadMealOptions();


  ReactDOM.render(<MealEconomyApp store={MealEconomyStore} />, el[0]);
}

const translateDimension = function (name) {
  var last = name.slice("dimension".length, name.length);
  if (!isNaN(last)) {
    if (name.slice(0, "dimension".length) === "dimension") {
      var key = `dimension${last}`
      if (MealEconomyStore.customAccountNames && MealEconomyStore.customAccountNames[key] && MealEconomyStore.customAccountNames[key].length > 0) {
        return MealEconomyStore.customAccountNames[key];
      }
      return `${Mashie.Resources.GetString("dimension")} ${last}`
    }
  }


  return name;
}


@observer
class MealEconomyApp extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    MealEconomyStore.loadList()
  }

  render() {

    return (
      <div>
        <CreateMealAccountDimentions></CreateMealAccountDimentions>
        <MealEconomyDimensionTable></MealEconomyDimensionTable>
      </div>
    );
  }
}


@observer
class CreateMealAccountDimentions extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  setSelectedDimension = (e) => {

    var selected = e.target.selectedOptions[0].value;
    MealEconomyStore.setSelectedDimension(selected)
  }
  addAccount = (e) => {
    e.preventDefault();
    MealEconomyStore.addAccount()
  }

  removeDimension = (e, i) => {
    e.preventDefault();
    MealEconomyStore.removeDimension(i)
  }

  changeDimensionValue = (key, value) => {
    MealEconomyStore.changeDimensionValue(key, value)
  }

  new = (e) => {
    e.preventDefault();
    MealEconomyStore.new()
  }

  save = (e) => {
    e.preventDefault();
    MealEconomyStore.save()
  }

  changeAccountType = (value) => {
    MealEconomyStore.changeAccountType(value)
  }
  changeAccount = (value) => {
    MealEconomyStore.changeAccount(value)
  }

  render() {

    // var availableDimensions = MealEconomyStore.availableDimensions.map(p => {
    //   return (
    //     <option value={p}>
    //       {p}
    //     </option>
    //   )
    // })

    var mealOptions = MealEconomyStore.mealOptions.map(p => {
      return (
        <option value={p.id}>{p.name}</option>
      )
    })

    var accountDimensions = MealEconomyStore.currentDimensions.map((p, i) => {

      var key = p[0];
      var translatedKey = translateDimension(key);

      var value = p[1];

      return (
        <tr>

          <td >
            <label style={{ marginRight: "13px" }}>{translatedKey}</label>
          </td>
          <td >
            <input onChange={(e) => this.changeDimensionValue(key, e.target.value)} value={value} />
          </td>
          {/* <td >
            <button onClick={(e) => this.removeAccount(e, i)} className="mashiebutton-remove"></button>
          </td> */}
        </tr>
      )
    })


    const account = (<tr>
      <td >
        <label style={{ fontWeight: "bold" }}>{Mashie.Resources.GetString("Account")}</label>
      </td>
      <td >
        <input onChange={(e) => this.changeAccount(e.target.value)} value={MealEconomyStore.selectedDimension.account} />
      </td>
    </tr>)



    return (
      <Grid rowTemplate="auto" columnTemplate="fit-content(400px) auto" style={{ alignItems: "center" }}>
        <GridCell>
          <fieldset style={{ margin: "20px 0 20px 0", borderRadius: "3px" }}>
            <legend>{Mashie.Resources.GetString("Legend_Create_Accounting_Dimensions")}</legend>
            <Grid rowTemplate="40px 40px 40px 40px" columnTemplate="fit-content(100px) auto" style={{ alignItems: "center" }}>
              <GridCell rowStart="1" rowEnd="1" columnStart="1" columnEnd="1" style={{ marginRight: "10px" }}>
                <label style={{ fontWeight: "bold" }}>{Mashie.Resources.GetString("MealOption")}</label>
              </GridCell>
              <GridCell rowStart="1" rowEnd="1" columnStart="2" columnEnd="2" >
                <select onChange={(e) => MealEconomyStore.changeMealOption(e.target.selectedOptions[0].value)} value={MealEconomyStore.selectedDimension.mealOptionId} disabled={MealEconomyStore.isEditMode}>
                  {mealOptions}
                </select>
              </GridCell>
              <GridCell rowStart="2" rowEnd="2" columnStart="1" columnEnd="1" style={{ marginRight: "10px" }}>
                <label style={{ fontWeight: "bold" }}>{Mashie.Resources.GetString("MealOptionGroup")}</label>
              </GridCell>
              <GridCell rowStart="2" rowEnd="2" columnStart="2" columnEnd="2">
                <label >{MealEconomyStore.mealOptionGroupName}</label>
              </GridCell>
              <GridCell rowStart="3" rowEnd="3" columnStart="1" columnEnd="1" style={{ marginRight: "10px" }}>
                <label style={{ fontWeight: "bold" }}>{Mashie.Resources.GetString("AccountType")}</label>
              </GridCell>
              <GridCell rowStart="3" rowEnd="3" columnStart="2" columnEnd="2">
                <select onChange={(e) => this.changeAccountType(e.target.selectedOptions[0].value)} value={MealEconomyStore.selectedDimension.type} disabled={MealEconomyStore.isEditMode}>
                  <option value="DEBIT" >{Mashie.Resources.GetString("AccountType.Debit")}</option>
                  <option value="CREDIT">{Mashie.Resources.GetString("AccountType.Credit")}</option>
                </select>
              </GridCell>

              {/* <GridCell rowStart="4" rowEnd="4" columnStart="1" columnEnd="1" style={{ marginRight: "10px" }}>
            <button onClick={this.addAccount} className="mashiebutton-add2" />
          </GridCell>
          <GridCell rowStart="4" rowEnd="4" columnStart="2" columnEnd="2">
            <select onChange={this.setSelectedDimension} value={MealEconomyStore.selectedDimension}>
              {availableDimensions}
            </select>
          </GridCell> */}
            </Grid>



            <table>
              <thead>
                {/* <tr>
              <th>{Mashie.Resources.GetString("AccountPart")}</th>
              <th>Värde</th>
            </tr> */}
              </thead>
              <tbody>
                {account}
                {accountDimensions}
              </tbody>
            </table>
            <button disabled={!MealEconomyStore.isEditMode} onClick={(e) => this.new(e)} className="mashiebutton1">
              {Mashie.Resources.GetString("New")}
            </button>
            <button onClick={(e) => this.save(e)} disabled={MealEconomyStore.isLocked} className="mashiebutton1">
              {Mashie.Resources.GetString("Save")}
            </button>

          </fieldset>
        </GridCell>
        <GridCell>
          {/* <code >
            {JSON.stringify(MealEconomyStore.selectedDimension)}
          </code> */}

          {/* <code >
            {JSON.stringify(MealEconomyStore.mealOptions)}
          </code> */}
        </GridCell>
      </Grid>

    );
  }
}


@observer
class MealEconomyDimensionTable extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }


  edit = (e, id) => {
    e.preventDefault();
    MealEconomyStore.edit(id)
  }

  delete = (e, id) => {
    e.preventDefault();
    MealEconomyStore.deleteMealAccount(id)
  }



  render() {

    var accountHeaders = [];

    MealEconomyStore.accountingList.map(p => {
      accountHeaders = [...new Set([...accountHeaders, ...Object.entries(p.accountDimensions).map(p => p[0])])]
    })

    var mealAccountRows = MealEconomyStore.accountingList.map((p, i) => {
      var isSelected = MealEconomyStore.selectedId === p.id
      var accountValues = accountHeaders.map((h) => {

        var accountValue = p.accountDimensions[h];

        if (!accountValue || accountValue === "") {
          accountValue = `${Mashie.Resources.GetString("Customer")}`;
        }

        return (
          <td>{accountValue}</td>
        )
      })

      var style = {
        backgroundColor: isSelected ? "rgb(158 171 193)" : "",
        color: isSelected ? "white" : "",
      }

      const classes = [
        "mashiebutton-edit"
      ].join(" ")

      return (
        <tr style={style} key={i}>
          <td >
            <button disabled={MealEconomyStore.isLocked} onClick={(e) => this.edit(e, p.id)} className={classes}></button>
          </td>
          <td>{p.mealOptionName}</td>
          <td>{p.mealOptionGroupName}</td>
          <td>{p.type == "DEBIT" ? Mashie.Resources.GetString("AccountType.Debit") : p.type == "CREDIT" ? Mashie.Resources.GetString("AccountType.Credit") : p.type}</td>
          <td>{p.account}</td>
          {accountValues}
          <td>
            <button disabled={MealEconomyStore.isLocked} onClick={(e) => this.delete(e, p.id)} className="mashiebutton-remove"></button>
          </td>
        </tr>

      )
    })
    var accountHeaderRow = accountHeaders.map(p => {
      return (
        <th>{translateDimension(p)}</th>
      )
    });

    var table = (<table className="mashie-table">
      <thead>
        <tr>
          <th></th>
          <th>{Mashie.Resources.GetString("MealOption")}</th>
          <th>{Mashie.Resources.GetString("MealOptionGroup")}</th>
          <th>{Mashie.Resources.GetString("AccountType")}</th>
          <th>{Mashie.Resources.GetString("Account")}</th>
          {accountHeaderRow}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {mealAccountRows}

      </tbody>
    </table>)

    return (
      accountHeaderRow.length > 0 ? table : null
    );
  }
}
